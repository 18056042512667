import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import parseHtml from 'html-react-parser'

import Link from '@components/link'
import { Facebook, Instagram } from 'react-feather'

const Footer = ({ lang = 'en-gb' }) => {
  const { allPrismicFooter } = useStaticQuery(footerQuery)
  const { footer: localeFooter } = allPrismicFooter.nodes.find(node => node.lang === lang)
  const { footer } = allPrismicFooter.nodes.find(node => node.lang === 'en-gb')

  const SocialIcons = () => (
    <div className="footer-icons">
      {footer.facebook && <a href={footer.facebook.url} target={footer.facebook.target} rel="noopener noreferrer" aria-label="Facebook"><Facebook color="#702E3F" /></a>}
      {/* {footer.twitter && <a href={footer.twitter.url} target={footer.twitter.target} rel="noopener noreferrer" aria-label="Twitter"><Twitter color="#702E3F" /></a>}
      {footer.pinterest && <a href={footer.pinterest.url} target={footer.pinterest.target} rel="noopener noreferrer" aria-label="Pinterest"><MapPin color="#702E3F" /></a>} */}
      {footer.instagram && <a href={footer.instagram.url} target={footer.instagram.target} rel="noopener noreferrer" aria-label="Instagram"><Instagram color="#702E3F" /></a>}
    </div>
  )

  return (
    <footer className="footer">
      <div className="columns is-paddingless is-marginless is-vcentered">
        <div className="column is-3 is-paddingless has-text-centered">
          <figure className="image has-margin-bottom-70">
            <Img fixed={footer.company_logo.localFile.childImageSharp.fixed} alt={footer.company_logo.alt} />
          </figure>
          <SocialIcons />
        </div>
        <div className="column has-text-centered">
          <figure className="image footer-image">
            <Img fixed={footer.center_image.localFile.childImageSharp.fixed} alt={footer.center_image.alt} />
          </figure>
          <br/>
          <div className="level footer-links">
            {localeFooter.links.map(({ link, title }, i) => (
              <div key={i} className="level-item has-text-centered">
                {link && (
                  <Link to={link.slug} lang={link.lang} fade>
                    {title.text}
                  </Link>
                )}
                {!link && title.text}
              </div>
            ))}
          </div>
        </div>
        <div className="column is-2 is-paddingless">
          <div className="content footer-address">
            <h5>{footer.company_name.text}</h5>
            {parseHtml(footer.address.html)}
            <br/>
            {parseHtml(footer.extra_fields.html)}
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  lang: PropTypes.string.isRequired
}

export default Footer

const footerQuery = graphql`{
  allPrismicFooter {
    nodes {
      lang
      footer: data {
        company_name {
          text
        }
        company_logo {
          alt
          localFile {
            childImageSharp {
              fixed (width: 200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        center_image {
          alt
          localFile {
            childImageSharp {
              fixed (width: 250) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        address {
          html
        }
        extra_fields {
          html
        }
        facebook {
          url
          target
        }
        instagram {
          url
          target
        }
        links {
          title {
            text
          }
          link {
            lang
            slug
          }
        }
      }
    }
  }
}`
