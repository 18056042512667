import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Link from '@components/link'
import { Menu, X } from 'react-feather'

const Navbar = ({ lang, alternateLanguages = [] }) => {
  const [isActive, setActive] = useState(false)
  const { logo, locales, allPrismicNavbar } = useStaticQuery(NavbarQuery)

  const { navbar: currentNavbar } = allPrismicNavbar.nodes.find(navbar => navbar.lang === lang)

  const AlternateLangLinks = () => locales.data.nodes.map((locale, i) => {
    const { locale_slug, locale_name } = locale

    const alternateLink = alternateLanguages.find(([lang]) => locale_slug.text === lang)
    const [lang, slug] = alternateLink || [locale_slug.text, '/']

    return (
      <div key={i} className="level-item">
        <Link className="has-text-white is-size-7 is-uppercase has-padding-right-20" to={slug} lang={lang} cover>
          {locale_name.text}
        </Link>
      </div>
    )
  })
  return (
    <nav className="navbar is-fixed-top" role="navigation">
      <div className={`quickview is-left ${isActive && 'is-active'}`}>
        <header className="quickview-header">
          <button className="menu-button" onClick={() => setActive(false)} aria-label="Close Menu">
            <X color="#fff" />
          </button>
        </header>

        <div className="quickview-body">
          <div className="quickview-block">
            {currentNavbar.links.map((link, i) => (
              <Link key={i} className="quickview-item" to={link.page.slug} lang={link.page.lang} fade>
                {link.title.text}
              </Link>
            ))}
          </div>
        </div>

        <footer className="quickview-footer is-hidden">
          <AlternateLangLinks />
        </footer>
      </div>
      <div className="level is-mobile is-full-width has-margin-left-60 has-margin-right-60">
        <div className="level-left">
          <div className="level-item">
            <button className={`menu-button ${isActive && 'is-active'}`} onClick={() => setActive(true)} aria-label="Open Menu">
              <Menu color="#fff" />
            </button>
          </div>
        </div>
        <div className="level-item">
          <Link to="/" lang={lang}>
            <figure className="image">
              <Img fixed={logo.childImageSharp.fixed} alt="JMyatt &amp; Co Logo" />
            </figure>
          </Link>
        </div>
        <div className="level-right">
          <AlternateLangLinks />
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  lang: PropTypes.string.isRequired,
  alternateLanguages: PropTypes.array
}

export default Navbar

const NavbarQuery = graphql`query Navbar {
  logo: file(name: {eq: "Myatts_Logo_Web_Header"}) {
    childImageSharp {
      fixed (width: 180) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  locales: prismicNavbar (lang: {eq: "en-gb"}) {
    data {
      nodes: locale {
        locale_slug {
          text
        }
        locale_name {
          text
        }
      }
    }
  }
  allPrismicNavbar {
    nodes {
      lang
      navbar: data {
        links {
          title {
            text
          }
          page {
            slug
            lang
          }
        }
      }
    }
  }
}`
