import React from 'react'
import PropTypes from 'prop-types'

import Navbar from '@components/navbar'
import Footer from '@components/footer'

import '../styles/main.scss'
import 'typeface-lato'
import 'typeface-playfair-display'

const Layout = ({ children, lang, alternateLanguages }) => {
  return (
    <>
      <Navbar lang={lang} alternateLanguages={alternateLanguages} />
      {children}
      <Footer lang={lang} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string.isRequired,
  alternateLanguages: PropTypes.array
}

export default Layout
