import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, title, seo, twitterTitle, twitterDescription }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  let meta = {
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    image: ''
  }
  let twitterMeta = {
    title: twitterTitle || site.siteMetadata.title,
    description: twitterDescription || site.siteMetadata.description,
    image: ''
  }
  if (seo) {
    const general = seo.find(card => card.slice_type === 'general_card')
    const twitter = seo.find(card => card.slice_type === 'twitter_card')

    if (general) {
      meta = {
        title: general.primary.title.text,
        description: general.primary.description.text,
        image: general.primary.image ? general.primary.image.localFile.childImageSharp.fixed.src : ''
      }
    }

    if (twitter) {
      twitterMeta = {
        title: twitter.primary.title.text,
        description: twitter.primary.description.text,
        image: twitter.primary.image ? twitter.primary.image.localFile.childImageSharp.fixed.src : ''
      }
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={meta.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description
        },
        {
          property: `og:title`,
          content: meta.title
        },
        {
          property: `og:description`,
          content: meta.description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: twitterMeta.title
        },
        {
          name: `twitter:description`,
          content: twitterMeta.description
        },
        {
          name: `twitter:image`,
          content: twitterMeta.image
        }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  seo: PropTypes.arrayOf(PropTypes.object),
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string
}

export default SEO

export const SEOFragments = graphql`
fragment PageSEO on PrismicPageData {
  seo {
    ... on PrismicPageSeoTwitterCard {
      slice_type
      primary {
        card_type
        description {
          text
        }
        image {
          localFile {
            childImageSharp {
              fixed(height: 500, width: 500, fit: COVER, cropFocus: ENTROPY) {
                src
              }
            }
          }
        }
        title {
          text
        }
      }
    }
    ... on PrismicPageSeoGeneralCard {
      slice_type
      primary {
        description {
          text
        }
        image {
          localFile {
            childImageSharp {
              fixed(height: 500, width: 500, fit: COVER, cropFocus: ENTROPY) {
                src
              }
            }
          }
        }
        title {
          text
        }
      }
    }
  }
}
fragment HomeSEO on PrismicHomepageData {
  seo {
    ... on PrismicHomepageSeoTwitterCard {
      slice_type
      primary {
        card_type
        description {
          text
        }
        image {
          localFile {
            childImageSharp {
              fixed(height: 500, width: 500, fit: COVER, cropFocus: ENTROPY) {
                src
              }
            }
          }
        }
        title {
          text
        }
      }
    }
    ... on PrismicHomepageSeoGeneralCard {
      slice_type
      primary {
        description {
          text
        }
        image {
          localFile {
            childImageSharp {
              fixed(height: 500, width: 500, fit: COVER, cropFocus: ENTROPY) {
                src
              }
            }
          }
        }
        title {
          text
        }
      }
    }
  }
}
`
