import React, { createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import BackgroundImg from 'gatsby-background-image'

const Header = ({ title, text = '', image, video = {} }) => {
  const fluid = image.localFile.childImageSharp.fluid
  const alt = image.alt

  const videoEl = createRef()

  useEffect(() => {
    if (video.url) {
      videoEl.current.play()
    }
  })
  return (
    <BackgroundImg Tag="header" className="hero is-fullheight video" fluid={fluid} alt={alt}>
      {video.url && (
        <div className="hero-video">
          <video ref={videoEl} muted loop preload="auto" style={{ objectFit: 'cover' }}>
            <source src={video.url} type="video/mp4" />
          </video>
        </div>
      )}
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-4 is-uppercase has-text-white has-text-weight-medium">{title}</h1>
          <h3 className="subtitle has-text-white has-text-weight-light" style={{ maxWidth: '560px', margin: 'auto' }}>{text}</h3>
        </div>
      </div>
    </BackgroundImg>
  )
}

Header.propTypes = {
  image: PropTypes.object.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  video: PropTypes.object
}

export default Header
