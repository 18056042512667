import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Link = ({ children, to, lang = 'en-gb', animation = 'fade', direction, className }) => {
  if (to === 'privacy-policy') lang = 'en-gb'
  if (to === '/') to = ''
  const destination = lang === 'en-gb' ? `/${to}` : `/${lang}/${to}`
  return (
    <AniLink to={destination} className={className} bg="#702E3F" direction={direction} fade={animation === 'fade' ? true : undefined} cover={animation === 'cover' ? true : undefined}>
      {children}
    </AniLink>
  )
}

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  to: PropTypes.string,
  lang: PropTypes.string,
  animation: PropTypes.string,
  direction: PropTypes.string,
  className: PropTypes.string
}

export default Link

export const customLink = (type, { data: link }, content, children, index) => {
  const linkType = link.link_type

  if (linkType === 'Web') {
    return (
      <a key={link.id} href={link.url} target={link.target}>{content}</a>
    )
  }
  return (
    <Link key={link.id} to={link.slug} lang={link.lang}>
      {content}
    </Link>
  )
}
